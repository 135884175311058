import React, { useState, useEffect } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { Modal, Box, Typography, TextField, Snackbar } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Constants from "variables/Constants";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

const Tracker = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [tabel, setTabel] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [subject, setSubject] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [subjectId, setSubjectId] = useState([]);
  const [chapterId, setChapterId] = useState([]);
  const [subChapterId, setSubChapterId] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);
  const [trackerId, setTrackerId] = useState("");
  const [createSnackBar, setCreateSnackBar] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTracker();
    fetchSubjects();
    if (subjectId.length > 0) {
      fetchChapter();
    }
    if (chapterId.length > 0) {
      subchapters();
    }
  }, [subjectId, chapterId]);

  const subchapters = async () => {
    try {
      const subchapterRequests = chapterId.map((id) =>
        axios.get(
          // `https://admin.akclasses.in/api/subchapter/?chapter_id=${id}`
          Constants.TrackerSubChapter.getAll + `/?chapter_id=${id}`
        )
      );
      const subchapterResponses = await Promise.all(subchapterRequests);
      const allSubchapters = subchapterResponses.flatMap(
        (response) => response.data.data
      );
      setTableData(allSubchapters);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTracker = async () => {
    setLoading(true);
    try {
      const res = await axios.get(Constants.tracker.fetchTracker);
      setTabel(res.data);
    } catch (error) {
      console.log(error);
    }finally {
      setLoading(false);
    }
  };

  const fetchSubjects = async () => {
    try {
      const res = await axios.get(
        // `https://admin.akclasses.in/api/subject/`
        Constants.TrackerSubject.getAll
      );
      setSubject(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchChapter = async () => {
    try {
      const fetchChapterRequests = subjectId.map((id) =>
        axios.get(
          // `https://admin.akclasses.in/api/chapter/?subject_id=${id}`
          Constants.TrackerChapter.getAll + `/?subject_id=${subjectId}`
        )
      );
      const fetchChapterResponses = await Promise.all(fetchChapterRequests);
      const allChapters = fetchChapterResponses.flatMap(
        (response) => response.data.data
      );
      setChapter(allChapters);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const payLoad = {
      title: title,
      description: description,
      subjects: subjectId.map((id) => ({
        id: Number(id),
        chapters: chapterId.map((chapId) => ({
          id: Number(chapId),
          sub_chapters: subChapterId,
        })),
      })),
    };

    try {
      await axios.post(
        // `https://admin.akclasses.in/api/revisiontracker/${trackerId}`,
        Constants.tracker.fetchTracker + "/" + trackerId,
        payLoad
      );
      setSnackbarOpen(true);
      handleCloseUpdate();
      fetchTracker();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const confirmDelete = (id) => {
    setTrackerId(id);
    setOpenDeleteConfirm(true);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        // `https://admin.akclasses.in/api/revisiontracker/${trackerId}`
        Constants.tracker.fetchTracker + "/" + trackerId
      );
      setDeleteSnackbarOpen(true);
      setOpenDeleteConfirm(false);
      fetchTracker();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenUpdateModal = (tracker) => {
    console.log("Tracker data:", tracker);
    setTitle(tracker.trackers?.[0]?.title || "");
    setDescription(tracker.trackers?.[0]?.description || "");
    setSubjectId(tracker.subjects?.[0]?.id ? [tracker.subjects[0].id] : []);
    setChapterId(tracker.chapters?.[0]?.id ? [tracker.chapters[0].id] : []);
    setSubChapterId(
      tracker.subchapters?.[0]?.id ? [tracker.subchapters[0].id] : []
    );
    setTrackerId(tracker.tracking_id);
    setOpenUpdate(true);
  };

  const handleClose = () => {
    setOpenCreate(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payLoad = {
      title: title,
      description: description,
      subjects: subjectId.map((id) => ({
        id: Number(id),
        chapters: chapterId.map((chapId) => ({
          id: Number(chapId),
          sub_chapters: subChapterId,
        })),
      })),
    };

    try {
      await axios.post(Constants.tracker.postTracker, payLoad);
      setCreateSnackBar(true);
      setOpenCreate(false);
      fetchTracker();
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  const resetForm = () => {
    setTitle("");
    setDescription("");
    setSubjectId([]);
    setChapterId([]);
    setSubChapterId([]);
  };

  const handleCloseUpdate = () => {
    resetForm();
    setOpenUpdate(false);
  };

  const handleStatusChange = async (id, status) => {
    try {
      const updatedStatus = status === "active" ? "active" : "inactive";
      await axios.post(
        // `https://admin.akclasses.in/api/revisiontracker/${id}`
        Constants.tracker.fetchTracker + "/" + id,
        {
          status: updatedStatus,
        }
      );
      fetchTracker();
    } catch (error) {
      console.log(error);
    }
  };

  const modalStyle = {
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const Loader = () => (
    <>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <div style={loaderStyles.container}>
        <div style={loaderStyles.spinner}></div>
      </div>
    </>
  );

  const loaderStyles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    spinner: {
      border: "8px solid #f3f3f3",
      borderTop: "8px solid #3498db",
      borderRadius: "50%",
      width: "60px",
      height: "60px",
      animation: "spin 1s linear infinite",
    },
  };


  return (
    <div>
      {loading ? (
        <Loader/>
      ) :(
      <>
      <Modal
        open={openCreate}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Tracker Form
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <label>Subject</label>
              <Select
                multiple
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map((id) => subject.find((item) => item.id === id)?.title)
                    .join(", ")
                }
              >
                {subject.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={subjectId.includes(item.id)} />
                    <label>{item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <label>Chapter</label>
              <Select
                multiple
                value={chapterId}
                onChange={(e) => setChapterId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map((id) => chapter.find((item) => item.id === id)?.title)
                    .join(", ")
                }
              >
                {chapter.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={chapterId.includes(item.id)} />
                    <label>{item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <label>Subchapter</label>
              <Select
                multiple
                value={subChapterId}
                onChange={(e) => setSubChapterId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) => tableData.find((item) => item.id === id)?.title
                    )
                    .join(", ")
                }
              >
                {tableData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={subChapterId.includes(item.id)} />
                    <label>{item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              value={title}
              required
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              value={description}
              required
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button
              className="btn-round"
              color="rose"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal open={openDeleteConfirm} onClose={handleCloseDeleteConfirm}>
        <Box sx={modalStyle}>
          <Typography variant="h6">Confirm Deletion</Typography>
          <Typography>Are you sure you want to delete this tracker?</Typography>
          <Button color="success" onClick={handleDelete}>
            Confirm
          </Button>
          <Button color="rose" onClick={handleCloseDeleteConfirm}>
            Cancel
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Tracker
          </Typography>
          <form onSubmit={handleUpdate}>
            <FormControl fullWidth>
              <label>Subject</label>
              <Select
                multiple
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map((id) => subject.find((item) => item.id === id)?.title)
                    .join(", ")
                }
              >
                {subject.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={subjectId.includes(item.id)} />
                    <label>{item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <label>Chapter</label>
              <Select
                multiple
                value={chapterId}
                onChange={(e) => setChapterId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map((id) => chapter.find((item) => item.id === id)?.title)
                    .join(", ")
                }
              >
                {chapter.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={chapterId.includes(item.id)} />
                    <label>{item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <label>Subchapter</label>
              <Select
                multiple
                value={subChapterId}
                onChange={(e) => setSubChapterId(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map(
                      (id) => tableData.find((item) => item.id === id)?.title
                    )
                    .join(", ")
                }
              >
                {tableData.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={subChapterId.includes(item.id)} />
                    <label>{item.title}</label>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
            <Button
              className="btn-round"
              color="rose"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleCloseUpdate}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Updated Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />

      <Snackbar
        open={deleteSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setDeleteSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Deleted Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />

      <Snackbar
        open={createSnackBar}
        autoHideDuration={3000}
        onClose={() => setCreateSnackBar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Created successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger"></CardIcon>
            </CardHeader>
            <CardBody>
              <Button color="success" onClick={() => setOpenCreate(true)}>
                ADD tracker
              </Button>
              <Link to="/admin/trackerAlottedUser">
                <Button color="warning">Tracker Alloted User</Button>
              </Link>
              <Table
                tableHead={[
                  "ID",
                  "TITLE",
                  "Weightage",
                  "SUBJECT NAME",
                  "CHAPTER",
                  "SUB CHAPTER",
                  "ACTION",
                ]}
                tableData={tabel.map((item, index) => [
                  index + 1,
                  item.trackers.length > 0 ? item.trackers[0].title : "N/A",
                  item.trackers.length > 0
                    ? item.trackers[0].description
                    : "N/A",
                  item.subjects.length > 0
                    ? item.subjects.map((subject) => subject.title).join(", ")
                    : "N/A",
                  item.chapters.length > 0
                    ? item.chapters.map((chapter) => chapter.title).join(",")
                    : "N/A",
                  item.subchapters.length > 0
                    ? item.subchapters.map((sub) => sub.title).join(",")
                    : "N/A",
                  [
                    <Button
                      style={{ backgroundColor: "#f72585" }}
                      key={`update-${index}`}
                      onClick={() => handleOpenUpdateModal(item)}
                      className="btn-round"
                    >
                      Update
                    </Button>,
                    <Button
                      style={{ backgroundColor: "#3a86ff" }}
                      key={`delete-${index}`}
                      onClick={() => confirmDelete(item.tracking_id)}
                      className="btn-round"
                    >
                      Delete
                    </Button>,
                  ],
                ])}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </>
)}
    </div>
  );
};

export default Tracker;
