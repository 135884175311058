
import React, { useState, useEffect } from "react";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { Modal, Box, Typography, TextField, Snackbar } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Constants from "variables/Constants";


const useStyles = makeStyles(styles);

const SubChapter = () => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  const [openCreate, setOpenCreate] = useState(false);
  const [subject, setSubject] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [chapterId, setChapterId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deleteSubChapterId, setDeleteSubChapterId] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [updateSubChapterId, setUpdateSubChapterId] = useState(null);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchAllSubChapter();

    fetchSubjects();
    if(subjectId){
      fetchChapter();
    }
    
  }, [subjectId]); 
  
  const fetchAllSubChapter= async()=>{
    try {
      const res = await axios.get(
        // `https://admin.akclasses.in/api/subchapter/`
        Constants.TrackerSubChapter.getAll

      )
      console.log(res.data.data)
      setTableData(res.data.data);
    } catch (error) {
      console.log(error)
    }

  }

  const fetchSubjects = async () => {
    try {
      const res = await axios.get(Constants.TrackerSubject.getAll);
      setSubject(res.data.data);
      console.log(res.data.data , 'subject');
      
    } catch (error) {
      console.error(error);
    }
  };

  const fetchChapter = async () => {
    try {
      const res = await axios.get(
        // `https://admin.akclasses.in/api/chapter/?subject_id=${subjectId}`
        Constants.TrackerChapter.getAll + `/?subject_id=${subjectId}`
  
      );
      setChapter(res.data.data);
      console.log(res.data.data ,'chapter');
      
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    const payLoad = {
      title,
      description,
      subject_id: subjectId,
      chapter_id: chapterId,
    };
  
    try {
      await axios.post(
        // `https://admin.akclasses.in/api/subchapter/${updateSubChapterId}`
        Constants.TrackerSubChapter.getAll + "/"+updateSubChapterId
        , 
        payLoad
      );
      setSnackbarOpen(true);
      handleCloseUpdate();
      fetchAllSubChapter();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const confirmDelete = (subChapterId) => {
    setDeleteSubChapterId(subChapterId);
    setOpenDeleteConfirm(true);
  };

  const handleDelete = async () => {
    try {
      const res = await axios.delete(
        // `https://admin.akclasses.in/api/subchapter/${deleteSubChapterId}`
        Constants.TrackerSubChapter.getAll+ "/" +deleteSubChapterId
      );
      setDeleteSnackbarOpen(true);
      console.log(res.data);
      setOpenDeleteConfirm(false);
      fetchAllSubChapter();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenUpdateModal = (subchapter) => {
    setTitle(subchapter.title);
    setDescription(subchapter.description);
    setSubjectId(subchapter.subject.id);
    setChapterId(subchapter.chapter.id);
    setUpdateSubChapterId(subchapter.id);  
    setOpenUpdate(true);
  };

  const handleClose = () => {
    setOpenCreate(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payLoad = {
      title: title,
      description: description,
      chapter_id: chapterId,
      subject_id: subjectId,
    };
    try {
      const res = await axios.post(
        // `https://admin.akclasses.in/api/subchapter/`
        Constants.TrackerSubChapter.getAll
        ,
        payLoad
      );
      console.log(res.data);
      setOpenCreate(false);
      fetchAllSubChapter();
      setSubjectId("");
      setChapterId("");
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseUpdate = () => {
    setSubjectId("");
    setTitle("");
    setDescription("");
    // setUpdateChapterId(null);
    setUpdateSubChapterId(null);
    setOpenUpdate(false);
  };

  const handleStatusChange = async (id, status) => {
    try {
      const updatedStatus = status === "active" ? "active" : "inactive";
      await axios.post(
        // `https://admin.akclasses.in/api/subchapter/${id}`
        Constants.TrackerSubChapter.getAll+"/"+id

        , {
        status: updatedStatus,
      });
      fetchAllSubChapter();
    } catch (error) {
      console.log(error);
    }
  };

  const modalStyle = {
    borderRadius: "7px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    textAlign:"center",
    
  
  
  };
  return (
    <div>
      <Modal
        open={openCreate}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Subchapter form
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <label>Subject</label>
              <Select
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
              >
                {subject.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
  <label>Chapter</label>
  <Select
    value={chapterId}
    onChange={(e) => setChapterId(e.target.value)}
  >
    {chapter.map((item, index) => (
      <MenuItem key={index} value={item.id}>
        {item.title}
      </MenuItem>
    ))}
  </Select>
</FormControl>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              name="title"
              required
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              name="description"
              required
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
            <Button
              className="btn-round"
              color="rose"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Modal open={openDeleteConfirm} onClose={handleCloseDeleteConfirm}>
        <Box sx={modalStyle}>
          <Typography variant="h6"><bold>Confirm Deletion</bold></Typography>
          <Typography>Are you sure you want to delete this chapter?</Typography>
         
          <Button style={{marginRight:10}}
           color="success" onClick={handleDelete}>
            Confirm
          </Button>
          <Button color="rose" onClick={handleCloseDeleteConfirm}>
            Cancel
          </Button>
     
        </Box>
      </Modal>

      <Modal
        open={openUpdate}
        onClose={handleCloseUpdate}
        aria-labelledby="update-modal-title"
        aria-describedby="update-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="update-modal-title" variant="h6" component="h2">
            Update Sub Chapter
          </Typography>
          <form onSubmit={handleUpdate}>
            {/* Subject Dropdown */}
            <FormControl fullWidth>
              <label>Subject</label>
              <Select
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)} // Update the subject ID when changed
              >
                {subject.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <label>chapters</label>
              <Select
                value={chapterId}
                onChange={(e) => setChapterId(e.target.value)}
              >
                {chapter.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Weightage"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Button
              className="btn-round"
              color="info"
              type="submit"
              variant="contained"
              sx={{ mt: 2 }}
            >
              Update
            </Button>
            <Button
              className="btn-round"
              color="rose"
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              onClick={handleCloseUpdate}
            >
              Cancel
            </Button>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Updated Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />
      <Snackbar
        open={deleteSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setDeleteSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
        message="Deleted Successfully"
        ContentProps={{
          sx: {
            backgroundColor: "green",
          },
        }}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="danger"></CardIcon>
            </CardHeader>
            <CardBody>
            

              <Button color="success" onClick={() => setOpenCreate(true)}>
                ADD SUBCHAPTER
              </Button>

              <Table style={{borderRadius:6}}
                tableHead={[
                  "ID",
                  "SUBJECT NAME",
                  "CHAPTER",
                  "TITLE",
                  "Weightage",
                  "Status",
                  "Actions",
                ]}
                tableData={tableData.map((item, index) => [
                  index + 1,
                  item.subject?.title,
                  item.chapter?.title,
                  item.title,
                  item.description,

                  [
                    <Select
                      key={index}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={item.status}
                      inputProps={{
                        name: "status",
                      }}
                      onChange={(e) =>
                        handleStatusChange(item.id, e.target.value)
                      }
                      onClick={(e) => console.log(e.target.value)}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="active"
                      >
                        Active
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="inactive"
                      >
                        Inactive
                      </MenuItem>
                    </Select>,
                  ],
                  [
                    <Button
                      key={`update-${index}`}
                      onClick={() => handleOpenUpdateModal(item)}
                      className="btn-round"
                      color="warning"
                    >
                      Update
                    </Button>,
                 <Button
                 key={`delete-${index}`}
                 onClick={() => confirmDelete(item.id)}
                 className="btn-round"
                 color="info"
               >
                 Delete
               </Button>
                  ],
                ])}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default SubChapter;
