import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import AddCategory from "AddViews/AddCategory";
import UpdateCategory from "UpdateViews/UpdateCategory";
import AddProductKey from "AddViews/AddProductKey";
import UpdateProductKey from "UpdateViews/UpdateProductKey";
import AddCoursesMaster from "AddViews/AddCoursesMaster";
import UpdateCoursesMasrter from "UpdateViews/UpdateCoursesMasrter";
import AddHomeSliderImages from "AddViews/AddHomeSliderImages";
import UpdateHomeSliderImage from "UpdateViews/UpdateHomeSliderImage";
import UpdateFranchiseeCenter from "UpdateViews/UpdateFranchiseeCenter";
import AddFranchiseeCenter from "AddViews/AddFranchiseeCenter";
import AddFaceToFaceUser from "AddViews/AddFaceToFaceUser";
import UpdateFaceToFaceUser from "UpdateViews/UpdateFaceToFaceUser";
import UpdateFAQ from "UpdateViews/UpdateFAQ";
import AddFAQ from "AddViews/AddFAQ";
import AddDownloadMaster from "AddViews/AddDownloadMaster";
import UpdateDownloadMaster from "UpdateViews/UpdateDownloadMaster";
import UpdateCouponCode from "UpdateViews/UpdateCouponCode";
import AddCouponCode from "AddViews/AddCouponCode";
import AddBatchTiming from "AddViews/AddBatchTiming";
import UpdateBatchTiming from "UpdateViews/UpdateBatchTiming";
import AddTestimonialMaster from "AddViews/AddTestimonialMaster";
import UpdateTestimonialMaster from "UpdateViews/UpdateTestimonialMaster";
import AddFreeUser from "AddViews/AddFreeUser";
import AddBookUser from "AddViews/AddBookUser";
import AddIncExpCat from "AddViews/AddIncExpCat";
import AddExpenseMode from "AddViews/AddExpenseMode";
import AddExpenseEntry from "AddViews/AddExpenseEntry";
import AddIncomeEntry from "AddViews/AddIncomeEntry";
import UpdateIncExpCat from "UpdateViews/UpdateIncExpCat";
import UpdateExpenseMode from "UpdateViews/UpdateExpenseMode";
import UpdateExpenseEntry from "UpdateViews/UpdateExpenseEntry";
import UpdateIncomeEntry from "UpdateViews/UpdateIncomeEntry";
import AddLogistic from "AddViews/AddLogistic";
import UpdateLogistic from "UpdateViews/UpdateLogistic";
import UpdateVendor from "UpdateViews/UpdateVendor";
import AddVendor from "AddViews/AddVendor";
import UpdateFaculty from "UpdateViews/UpdateFaculty";
import AddFaculty from "AddViews/AddFaculty";
import AddPremiunUser from "AddViews/AddPremiumUser";
import UpdateTestCategory from "UpdateViews/UpdateTestCategory";
import AddTestCategory from "AddViews/AddTestCategory";
import UpdateSubject from "UpdateViews/UpdateSubject";
import AddSubject from "AddViews/AddSubject";
import UpdateQuestion from "UpdateViews/UpdateQuestion";
import AddQuestion from "AddViews/AddQuestion";
import AddTestSeries from "AddViews/AddTestSeries";
import UpdateTestSeries from "UpdateViews/UpdateTestSeries";
import UpdateOfflineOrder from "UpdateViews/UpdateOfflineOrder";
import AddOfflineOrder from "AddViews/AddOfflineOrder";
import init from "Helper/WindowToken";
import AddTestOrder from "AddViews/AddTestOrder";
import AddMediatype from "AddViews/AddMediatype";
import UpdateMediatype from "UpdateViews/UpdateMediatype";
import AddOStype from "AddViews/AddOStype";
import UpdateOStype from "UpdateViews/UpdateOStype";
import UpdateViewsValidity from "UpdateViews/UpdateViewsValidity";
import AddViewsValidity from "AddViews/AddViewsValidity";
import Variant from "views/Variant";
import AddVariant from "AddViews/AddVariant";
import AddCourseLevel from "AddViews/AddCourseLevel";
import UpdateCourseLevel from "UpdateViews/UpdateCourseLevel";
import AddTestLevel from "AddViews/AddTestLevel";
import UpdateTestLevel from "UpdateViews/UpdateTestLevel";
import AddCourseStream from "AddViews/AddCourseStream";
import UpdateCourseStream from "UpdateViews/UpdateCourseStream";
import AddTestStream from "AddViews/AddTestStream";
import UpdateTestStream from "UpdateViews/UpdateTestStream";
import AddBookLevel from "AddViews/AddBookLevel";
import UpdateBookLevel from "UpdateViews/UpdateBookLevel";
import AddBookStream from "AddViews/AddBookStream";
import UpdateBookStream from "UpdateViews/UpdateBookStream";
import AddBookMaster from "AddViews/AddBookMaster";
import UpdateBookMaster from "UpdateViews/UpdateBookMaster";
import AddExtensionMaster from "AddViews/AddExtensionMaster";
import UpdateExtensionMaster from "UpdateViews/UpdateExtensionMaster";
import AddBookCategory from "AddViews/AddBookCategory";
import UpdateBookCategory from "UpdateViews/UpdateBookCategory";
import AddBookOrder from "AddViews/AddBookOrder";
import AddExtensionOrder from "AddViews/AddExtensionOrder";
import UpdateTestOrder from "UpdateViews/UpdateTestOrder";
import UpdateBookOrder from "UpdateViews/UpdateBookOrder";
import AddSchedule from "AddViews/AddSchedule";
import UpdateSchedule from "UpdateViews/UpdateSchedule";
import AddActivationProcess from "AddViews/AddActivationProcess";
import UpdateActivationProcess from "UpdateViews/UpdateActivationProcess";
import AddCourseSubject from "views/AddCourseSubject";
import UpdateCourseSubject from "UpdateViews/UpdateCourseSubject";
import AddTestSubject from "views/AddTestSubject";
import AddBookSubject from "AddViews/AddBookSubject";
import UpdateBookSubject from "UpdateViews/UpdateBookSubject";
import UpdateTestSubject from "UpdateViews/UpdateTestSubject";
import AddBudgetMode from "AddViews/AddBudgetMode";
import AddCourseStrategy from "AddViews/AddCourseStrategy";
import CourseBudgets from "views/CourseBudgets";
import AddCourseBudget from "AddViews/AddCourseBudget";
import UpdateBudgetMode from "UpdateViews/UpdateBudgetMode";
import firebase from "firebase";
import AddVendorOfflineOrder from "AddViews/AddVendorOfflineOrder";
import UpdateVendorOfflineOrder from "UpdateViews/UpdateVendorOfflineOrder";
import AddExtendOrder from "AddViews/AddExtendOrder";
import UpdatePremiumUser from "UpdateViews/UpdatePremiumUser";
import AnsweraQuestion from "views/AnsweraQuestion";
import CreateQuestion from "AddViews/CreateQuestion";
import AddIncomeType from "AddViews/AddIncomeType";
import UpdateQuery from "UpdateViews/UpdateQuery";
import UpdateAd from "UpdateViews/UpdateAd";
import AddAd from "AddViews/AddAd";
import AddLevel from "AddViews/AddLevel";
import UpdateLevel from "UpdateViews/UpdateLevel";
import AddAppFaculty from "AddViews/AddAppFaculty";
import AddDownloadable from "AddViews/AddDownloadable";
import UpdateChapter from "UpdateViews/UpdateChapter";
import AddAppSubject from "AddViews/AddAppSubject";
import UpdateAppSubject from "UpdateViews/UpdateAppSubject";
import AddLiveQuiz from "AddViews/AddLiveQuiz";
import UpdateLiveQuiz from "UpdateViews/UpdateLiveQuiz";
import AddSeriesQuestion from "AddViews/AddSeriesQuestion";
import UpdateSeriesQuestion from "UpdateViews/UpdateSeriesQuestion";
import UpdateTopScore from "UpdateViews/UpdateTopScore";
import AddTopScore from "AddViews/AddTopScore";
import AddBackupOrder from "AddViews/AddBackupOrder";
import UpdateBackupOrder from "UpdateViews/UpdateBackupOrder";
import SendMessages from "AddViews/SendMessages";
import AddTemplate from "AddViews/AddTemplate";
import UpdateTemplate from "UpdateViews/UpdateTemplate";
import AddNotification from "AddViews/AddNotification";
import UpdateNotification from "UpdateViews/UpdateNotification";
import AddAnnouncement from "AddViews/AddAnnouncement";
import UpdateAnnouncement from "UpdateViews/UpdateAnnouncement";
import AddTopic from "AddViews/AddTopic";
import UpdateTopic from "UpdateViews/UpdateTopic";
import AddStrategy from "AddViews/AddStrategy";
import AddDay from "AddViews/AddDay";
import Days from "views/Days";
import UpdateStrategy from "UpdateViews/UpdateStrategy";
import UpdateDay from "UpdateViews/UpdateDay";
import AddQuatraEntry from "AddViews/AddQuatraEntry";
import UpdateQuatraEntry from "UpdateViews/UpdateQuatraEntry";
import UserStrategies from "views/UserStrategies";
import UserStatDays from "views/UserStatDays";
import AddUser from "AddViews/AddUser";
import AddAttempt from "AddViews/AddAttempt";
import UpdateAttempt from "UpdateViews/UpdateAttempt";
import UpdateMeeting from "UpdateViews/UpdateMeeting";
import AddMeeting from "AddViews/AddMeeting";
import AddLectureLink from "AddViews/AddLectureLink";
import AddReplica from "AddViews/AddReplica";
import AddQuestionBankLevel from "AddViews/AddQuestionBankLevel";
import UpdateQuestionBankLevel from "AddViews/UpdateQuestionBankLevel";
import AddQuestionBankSubject from "AddViews/AddQuestionBankSubject";
import UpdateQuestionBankSubject from "AddViews/UpdateQuestionBankSubject";
import AddQuestionBankChapter from "AddViews/AddQuestionBankChapter";
import AddMailTemplate from "AddViews/AddMailTemplate";
import UpdateQuestionBankChapter from "AddViews/UpdateQuestionBankChapter";
import AddQuestionBank from "AddViews/AddQuestionBank";
import UpdateQuestionBank from "AddViews/UpdateQuestionBank";
import AddLiveQuizQuestion from "views/AddLiveQuizQuestion";
import LiveQuizQuestion from "views/LiveQuizQuestion";
import TestSeriesQuestion from "views/TestSeriesQuestion";
import UpdateLiveQuizQuestion from "AddViews/UpdateLiveQuizQuestion";
import AddTestSeriesQuestion from "views/AddTestSeriesQuestion";
import AddMobileSliderImages from "AddViews/AddMobileSliderImages";
import UpdateMobileSliderImages from "UpdateViews/UpdateMobileSliderImages";
import AnswerQuery from "views/AnswerQuery";
import AddBatch from "AddViews/AddBatch";
import UpdateBatch from "UpdateViews/UpdateBatch";
import AddAmenity from "AddViews/AddAmenity";
import UpdateAmenity from "UpdateViews/UpdateAmenity";
import AddAmenities from "AddViews/AddAmenities";
import AddSource from "AddViews/AddSource";
import UpdateSource from "UpdateViews/UpdateSource";
import AddBatchWings from "AddViews/AddBatchWings";
import UpdateBatchWing from "UpdateViews/UpdateBatchWing";
import AddWings from "AddViews/AddWings";
import AddingQuestions from "AddViews/AddQuestions";
import SubjectQuestion from "views/SubjectQuestion";
import AddSubjectQuestion from "views/AddSubjectQuestion";
import AddFaceToFace from "AddViews/AddFaceToFace";
import UpdateFaceToFace from "UpdateViews/UpdateFaceToFace";
import AddFaceToFaceCategory from "AddViews/AddFaceToFaceCategory";
import UpdateFaceToFaceCategory from "UpdateViews/UpdateFaceToFaceCategory";
import AddFaceToFaceLevel from "AddViews/AddFaceToFaceLevel";
import UpdateFaceToFaceLevel from "UpdateViews/UpdateFaceToFaceLevel";
import AddFaceToFaceStream from "AddViews/AddFaceToFaceStream";
import UpdateFaceToFaceStream from "UpdateViews/UpdateFaceToFaceStream";
import AddFaceToFaceOfflineOrder from "AddViews/AddFaceToFaceOfflineOrder";
import UpdateFaceToFaceOfflineOrder from "UpdateViews/UpdateFaceToFaceOfflineOrder";
import AddFaceToFaceSubject from "views/AddFaceToFaceSubject";
import UpdateFaceToFaceSubject from "UpdateViews/UpdateFaceToFaceSubject";
import UpdateVendorOrdersOffline from "UpdateViews/UpdateVendorOrdersOffline";
import AddCommission from "AddViews/AddCommission";
import UpdateSubjectFilter from "UpdateViews/UpdateSubjectFilter";
import AddSubjectFilter from "AddViews/AddSubjectFilter";
import Monitor_UserStrategies from "../views/monitorUserStrategy";
import Monitor_User_Stat_Days from "views/monitorUserStatDays";
import HomePage from "ak_web/src/components/Screens/HomePage";
import Subjects from "ak_web/src/components/Subjects";
import CourseLanding from "ak_web/src/components/CourseLanding";
import CourseDetails from "ak_web/src/components/CourseDetails";
import FunctionalCheckout from "ak_web/src/components/checkout/FunctionalCheckout";
import Checkout from "ak_web/src/components/checkout/Checkout";
import ComboSubjects from "ak_web/src/components/ComboSubjects";
import Monitor_User_Days_Dashboard from "views/MonitorUserDaysDashboard";
import Add_Manual_Notification from "AddViews/AddManualNotification";
import Update_Manual_Notification from "UpdateViews/UpdateManualNotification";
import Add_FaceToFace_Batch from "AddViews/AddFaceToFaceBatch";
import Update_FaceToFace_Batch from "UpdateViews/UpdateFaceToFaceBatch";
import StudentRegistration from "views/StudentRegistration";
import FaceToFaceBatchDays from "views/FaceToFaceBatchDays";
import FaceToFaceBatchStudents from "views/FaceToFaceBatchStudents";
import Upload_Question_Docs from "views/uploadQuestionDocs";
import View_Face_Student_Attendance from "views/ViewFaceSudentAttendance";
import ExpenseTypeReport from "views/ExpenseTypeReport";
import IncomeTypeReport from "views/IncomeTypeReport";
import AddWhatStudentsSay from "AddViews/AddWhatStudentsSay";
import UpdateWhatStudentsSay from "UpdateViews/UpdateWhatStudentsSay";
import UpdateMailTemplate from "UpdateViews/UpdateMailTemplate";
//scanner
import AddScannerLevel from "AddViews/AddScannerLevel";
import UpdateScannerLevel from "AddViews/UpdateScannerLevel";
import UpdateScannerSubject from "AddViews/UpdateScannerSubject";
import UpdateScannerChapter from "AddViews/UpdateScannerChapter";
import AddScannerSubject from "AddViews/AddSacnnerSubject";
import AddScannerChapter from "AddViews/AddScannerChapter";
import UploadScannerDocs from "views/UploadScannerDocs";
import UpdateScannerSource from "AddViews/UpdateScannerSource";
import AddScannerSource from "AddViews/AddScannerSource";
import WalletManagement from "./../components/Wallet/WalletManagement"
import WalletAdd from "../components/Wallet/WalletAdd";
import Redeem from "../components/Wallet/Redeem";
import YouTubeMaterial from "../components/youtube/YouTubeMaterial"
import MainMaster from "../components/youtube/MainMaster"
// import Chapters from "../components/youtube/Chapters"
import SubChapter from "../components/youtube/SubChapter"
import YoutubeTry from "../components/youtube/YoutubeTry"
import Rchapter from "../components/youtube/Rchapter";
import Tracker from "../components/youtube/Tracker";
import Trackerdrops from "../components/youtube/Trackerdrops";
import FinalMaster from "components/youtube/FinalMaster";
import Results from "../views/Results"



var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const path_name = window.location.pathname.split("/")[2];

  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(false);
  const [color, setColor] = React.useState("red");
  const [bgColor, setBgColor] = React.useState("white");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo-white.svg"));
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount

  React.useEffect(() => {
    if (path_name !== "akWebPortal") {
      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = "hidden";
      }
      window.addEventListener("resize", resizeFunction);

      // Specify how to clean up after this effect:
      return function cleanup() {
        if (navigator.platform.indexOf("Win") > -1) {
          ps.destroy();
        }
        window.removeEventListener("resize", resizeFunction);
      };
    }
  });
  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    if (routes === undefined) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveRoute = getActiveRoute(routes[i].views);
          if (collapseActiveRoute !== activeRoute) {
            return collapseActiveRoute;
          }
        } else {
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path) !==
            -1
          ) {
            return routes[i].name;
          }
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  if (init() === "success") {
    const firebaseConfig = {
      apiKey: "AIzaSyBCfqCkmWRmTZV887b53_r00xHJwKsVvUM",
      authDomain: "ak-classes.firebaseapp.com",
      databaseURL: "https://ak-classes.firebaseio.com",
      projectId: "ak-classes",
      storageBucket: "ak-classes.appspot.com",
      messagingSenderId: "197903627850",
      appId: "1:197903627850:web:e8839e80fcde3162fbd8b3",
      measurementId: "G-84C4W89ZXH",
    };
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }
    // console.log(path_name)
    return (
      <div className={classes.wrapper}>
        {path_name !== "akWebPortal" ? (
          <div>
            <Sidebar
              routes={routes}
              logoText={"Creative Tim"}
              logo={logo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={color}
              bgColor={bgColor}
              miniActive={miniActive}
              {...rest}
            />
            <div className={mainPanelClasses} ref={mainPanel}>
              <AdminNavbar
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
                brandText={getActiveRoute(routes)}
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      {getRoutes(routes)}

                      <Route
                        path="/admin/uploadQuestionDocx"
                        component={Upload_Question_Docs}
                      />

                      <Route
                        path="/admin/AddFacetoFaceBatch"
                        component={Add_FaceToFace_Batch}
                      />
                     
                      <Route
                        path="/admin/updateFaceToFaceBatch"
                        component={Update_FaceToFace_Batch}
                      />

                      <Route
                        path="/admin/facetofacebatchdays/"
                        component={FaceToFaceBatchDays}
                      />
                      <Route
                        path="/admin/faceToFaceBatchStudents"
                        component={FaceToFaceBatchStudents}
                      />

                      <Route
                        path="/admin/viewfaceStudentAttendance"
                        component={View_Face_Student_Attendance}
                      />

                      <Route
                        path="/admin/studentsregistration/"
                        component={StudentRegistration}
                      />

                      <Route
                        path="/admin/addManualNotification"
                        component={Add_Manual_Notification}
                      />
                      <Route
                        path="/admin/updateManualNotification/"
                        component={Update_Manual_Notification}
                      />
                      <Route path="/admin/addreplica" component={AddReplica} />
                      <Route
                        path="/admin/coursevariants/:id"
                        component={Variant}
                      />
                      <Route
                        path="/admin/addcoursevariant/:id"
                        component={AddVariant}
                      />
                      <Route
                        path="/admin/userstats"
                        component={UserStrategies}
                      />
                      <Route
                        path="/admin/monitorUserStrategy/"
                        component={Monitor_UserStrategies}
                      />
                      <Route
                        path="/admin/monitorUserStatDays/"
                        component={Monitor_User_Stat_Days}
                      />
                      <Route
                        path="/admin/monitorUserDaysDashboard/"
                        component={Monitor_User_Days_Dashboard}
                      />
                      <Route
                        path="/admin/userstatdays"
                        component={UserStatDays}
                      />
                      <Route path="/admin/days" component={Days} />
                      <Route path="/admin/addday" component={AddDay} />
                      <Route path="/admin/adduser" component={AddUser} />
                      <Route
                        path="/admin/addcoursestrategy/:id"
                        component={AddCourseStrategy}
                      />
                      <Route path="/admin/addwings/:id" component={AddWings} />
                      <Route
                        path="/admin/addcommission/:id"
                        component={AddCommission}
                      />
                      <Route
                        path="/admin/updateday/:id"
                        component={UpdateStrategy}
                      />
                      <Route
                        path="/admin/addstrategy"
                        component={AddStrategy}
                      />
                      <Route
                        path="/admin/updatestrategy/:id"
                        component={UpdateDay}
                      />
                      <Route path="/admin/addmeeting" component={AddMeeting} />
                      <Route
                        path="/admin/addlecturelink"
                        component={AddLectureLink}
                      />
                      <Route
                        path="/admin/updatemeeting/:id"
                        component={UpdateMeeting}
                      />
                      <Route path="/admin/addattempt" component={AddAttempt} />
                      <Route
                        path="/admin/updatesource/:id"
                        component={UpdateSource}
                      />

                      <Route path="/admin/addsource" component={AddSource} />
                      <Route
                        path="/admin/updateattempt/:id"
                        component={UpdateAttempt}
                      />

                      <Route
                        path="/admin/addfacetoface"
                        component={AddFaceToFace}
                      />
                      <Route
                        path="/admin/updatefacetoface/:id"
                        component={UpdateFaceToFace}
                      />

                      <Route
                        path="/admin/coursebudgets/:id"
                        component={CourseBudgets}
                      />
                      <Route
                        path="/admin/addcoursebudget/:id"
                        component={AddCourseBudget}
                      />
                      <Route
                        path="/admin/extendofflineorder/:id"
                        component={AddExtendOrder}
                      />
                      <Route path="/admin/addtopic" component={AddTopic} />
                      <Route
                        path="/admin/updatetopic/:id"
                        component={UpdateTopic}
                      />
                      <Route
                        path="/admin/updateSubjectFilter/:id"
                        component={UpdateSubjectFilter}
                      />
                      <Route
                        path="/admin/addSubjectFilter"
                        component={AddSubjectFilter}
                      />
                      <Route
                        path="/admin/addquatraentry"
                        component={AddQuatraEntry}
                      />
                      <Route
                        path="/admin/updatequatraentry/:id"
                        component={UpdateQuatraEntry}
                      />
                      <Route
                        path="/admin/addcategory"
                        component={AddCategory}
                      />
                      <Route
                        path="/admin/addfacetofacecategory"
                        component={AddFaceToFaceCategory}
                      />
                      <Route
                        path="/admin/addfacetofacelevel"
                        component={AddFaceToFaceLevel}
                      />
                      <Route
                        path="/admin/addfacetofacestream"
                        component={AddFaceToFaceStream}
                      />
                      <Route
                        path="/admin/addfacetofacesubject"
                        component={AddFaceToFaceSubject}
                      />
                      <Route
                        path="/admin/addfacetofaceofflineorder"
                        component={AddFaceToFaceOfflineOrder}
                      />
                      <Route
                        path="/admin/updatefacetofacesubject/:id"
                        component={UpdateFaceToFaceSubject}
                      />
                      <Route
                        path="/admin/updatefacetofaceofflineorder/:id"
                        component={UpdateFaceToFaceOfflineOrder}
                      />
                      <Route
                        path="/admin/updatefacetofacestream/:id"
                        component={UpdateFaceToFaceStream}
                      />
                      <Route
                        path="/admin/updatefacetofacelevel/:id"
                        component={UpdateFaceToFaceLevel}
                      />
                      <Route
                        path="/admin/updatefacetofacecategory/:id"
                        component={UpdateFaceToFaceCategory}
                      />
                      <Route
                        path="/admin/updatecategory/:id"
                        component={UpdateCategory}
                      />
                      <Route
                        path="/admin/addbackuporder"
                        component={AddBackupOrder}
                      />
                      <Route
                        path="/admin/updatebackuporder/:id"
                        component={UpdateBackupOrder}
                      />
                      <Route
                        path="/admin/addtemplate"
                        component={AddTemplate}
                      />
                      <Route
                        path="/admin/updatetemplate/:id"
                        component={UpdateTemplate}
                      />
                      <Route
                        path="/admin/addlivequizquestion/:id"
                        component={AddLiveQuizQuestion}
                      />
                      <Route
                        path="/admin/updatelivequizquestion"
                        component={UpdateLiveQuizQuestion}
                      />
                      <Route
                        path="/admin/livequizquestion"
                        component={LiveQuizQuestion}
                      />
                      <Route
                        path="/admin/addtestseriesquestion/:id"
                        component={AddTestSeriesQuestion}
                      />
                      <Route
                        path="/admin/addsubjectquestion/:id"
                        component={AddSubjectQuestion}
                      />
                      <Route
                        path="/admin/testseriesquestion/:id"
                        component={TestSeriesQuestion}
                      />
                      <Route
                        path="/admin/subjectquestion/:id"
                        component={SubjectQuestion}
                      />
                      <Route
                        path="/admin/addlivequiz"
                        component={AddLiveQuiz}
                      />
                      <Route
                        path="/admin/updatelivequiz/:id"
                        component={UpdateLiveQuiz}
                      />
                      {/* Question Bank Routes Start */}
                      <Route
                        path="/admin/addquestionbank"
                        component={AddQuestionBank}
                      />
                      <Route
                        path="/admin/updatequestionbank/:id"
                        component={UpdateQuestionBank}
                      />
                      <Route
                        path="/admin/addingQuestion"
                        component={AddingQuestions}
                      />

                      <Route
                        path="/admin/addquestionbanklevel"
                        component={AddQuestionBankLevel}
                      />
                      <Route
                        path="/admin/updatequestionbanklevel/:id"
                        component={UpdateQuestionBankLevel}
                      />

                      <Route
                        path="/admin/addquestionbanksubject"
                        component={AddQuestionBankSubject}
                      />
                      <Route
                        path="/admin/updatequestionbanksubject/:id"
                        component={UpdateQuestionBankSubject}
                      />

                      <Route
                        path="/admin/addquestionbankchapter"
                        component={AddQuestionBankChapter}
                      />
                      <Route
                        path="/admin/updatequestionbankchapter/:id"
                        component={UpdateQuestionBankChapter}
                      />
                      {/* Question Bank Routes End */}
                      {/* Scanner Start */}
                      <Route
                        path="/admin/addscannerlevel"
                        component={AddScannerLevel}
                      />
                      <Route
                        path="/admin/addscannerchapter"
                        component={AddScannerChapter}
                      />
                      <Route
                        path="/admin/updatescannersource"
                        component={UpdateScannerSource}
                      />
                      <Route
                        path="/admin/addscannersubject"
                        component={AddScannerSubject}
                      />
                      <Route
                        path="/admin/updatescannerlevel"
                        component={UpdateScannerLevel}
                      />
                      <Route
                        path="/admin/updatescannersubject"
                        component={UpdateScannerSubject}
                      />
                      <Route
                        path="/admin/updatescannerchapter"
                        component={UpdateScannerChapter}
                      />
                      <Route
                        path="/admin/uploadScannerDocs"
                        component={UploadScannerDocs}
                      />
                      <Route
                        path="/admin/addscannersource"
                        component={AddScannerSource}
                      />
                      {/* Scanner end */}
                      <Route
                        path="/admin/addtopscore"
                        component={AddTopScore}
                      />
                      <Route
                        path="/admin/updatetopscore/:id"
                        component={UpdateTopScore}
                      />
                      <Route
                        path="/admin/addseriesquestion"
                        component={AddSeriesQuestion}
                      />
                      <Route
                        path="/admin/updateseriesquestion/:id"
                        component={UpdateSeriesQuestion}
                      />
                      <Route
                        path="/admin/addschedule"
                        component={AddSchedule}
                      />
                      <Route
                        path="/admin/updateschedule/:id"
                        component={UpdateSchedule}
                      />
                      <Route
                        path="/admin/addbudgetmode"
                        component={AddBudgetMode}
                      />
                      <Route
                        path="/admin/updatebudgetmode/:id"
                        component={UpdateBudgetMode}
                      />
                      <Route
                        path="/admin/addcourselevel"
                        component={AddCourseLevel}
                      />
                      <Route
                        path="/admin/updatecourselevel/:id"
                        component={UpdateCourseLevel}
                      />
                      <Route
                        path="/admin/addvendorofflineorder"
                        component={AddVendorOfflineOrder}
                      />
                      <Route
                        path="/admin/updatevendorofflineorder/:id"
                        component={UpdateVendorOfflineOrder}
                      />
                      <Route
                        path="/admin/updatevendorordersoffline/:id"
                        component={UpdateVendorOrdersOffline}
                      />
                      <Route
                        path="/admin/addactivationprocess"
                        component={AddActivationProcess}
                      />
                      <Route
                        path="/admin/updateactivationprocess/:id"
                        component={UpdateActivationProcess}
                      />
                      <Route
                        path="/admin/addcoursesubject"
                        component={AddCourseSubject}
                      />
                      <Route
                        path="/admin/updatecoursesubject/:id"
                        component={UpdateCourseSubject}
                      />
                      <Route
                        path="/admin/addtestsubject"
                        component={AddTestSubject}
                      />
                      <Route
                        path="/admin/updatetestsubject/:id"
                        component={UpdateTestSubject}
                      />
                      <Route
                        path="/admin/addbooksubject"
                        component={AddBookSubject}
                      />
                      <Route
                        path="/admin/updatebooksubject/:id"
                        component={UpdateBookSubject}
                      />
                      <Route
                        path="/admin/addtestlevel"
                        component={AddTestLevel}
                      />
                      <Route
                        path="/admin/updatetestlevel/:id"
                        component={UpdateTestLevel}
                      />
                      <Route
                        path="/admin/addcoursestream"
                        component={AddCourseStream}
                      />
                      <Route
                        path="/admin/updatecoursestream/:id"
                        component={UpdateCourseStream}
                      />
                      <Route
                        path="/admin/addteststream"
                        component={AddTestStream}
                      />
                      <Route
                        path="/admin/updateteststream/:id"
                        component={UpdateTestStream}
                      />
                      <Route
                        path="/admin/addproductkey"
                        component={AddProductKey}
                      />
                       <Route
                        path="/admin/wallet/:id"
                        component={WalletManagement}
                      />
                       <Route
                        path="/admin/leadership/:id"
                        component={Results}
                      />
                       <Route
                        path="/admin/viewUser/:id"
                        component={FinalMaster}
                      />
                       {/* <Route
                        path="/admin/walletadd/:id"
                        component={WalletAdd}
                      /> */}
                       <Route
                        path="/admin/redeem/:id"
                        component={Redeem}
                      />
                       <Route
                        path="/admin/youtubematerial"
                        component={YouTubeMaterial}
                      />
                       <Route
                        path="/admin/YoutubeTry"
                        component={YoutubeTry}
                      />
                       <Route
                        path="/admin/mainmaster"
                        component={MainMaster}
                      />
                     

                      <Route
                      path="/admin/revichapter"
                      component={Rchapter}
                      />
                      <Route
                      path="/admin/tracker"
                      componenet={Tracker}
                      />

                       <Route
                        path="/admin/subchapter"
                        component={SubChapter}
                      />
                      <Route
                        path="/admin/updatemailtemplate/:id"
                        component={UpdateMailTemplate}
                      />
                      <Route
                        path="/admin/updateproductkey/:id"
                        component={UpdateProductKey}
                      />
                      <Route path="/admin/addbatch" component={AddBatch} />
                      <Route
                        path="/admin/updatebatch/:id"
                        component={UpdateBatch}
                      />
                      <Route path="/admin/addamenity" component={AddAmenity} />
                      <Route
                        path="/admin/addamenities/:id"
                        component={AddAmenities}
                      />
                      <Route
                        path="/admin/updateamenity/:id"
                        component={UpdateAmenity}
                      />
                      <Route
                        path="/admin/addcoursesmaster"
                        component={AddCoursesMaster}
                      />
                      <Route
                        path="/admin/updatecoursesmaster/:id"
                        component={UpdateCoursesMasrter}
                      />
                      <Route
                        path="/admin/addbatchwing"
                        component={AddBatchWings}
                      />
                      <Route
                        path="/admin/updatebatchwing/:id"
                        component={UpdateBatchWing}
                      />
                      <Route
                        path="/admin/addbatchtiming"
                        component={AddBatchTiming}
                      />
                      <Route
                        path="/admin/updatebatchtiming/:id"
                        component={UpdateBatchTiming}
                      />
                      <Route
                        path="/admin/addcouponcode"
                        component={AddCouponCode}
                      />
                      <Route
                        path="/admin/updatecouponcode/:id"
                        component={UpdateCouponCode}
                      />
                      <Route
                        path="/admin/adddownloadmaster"
                        component={AddDownloadMaster}
                      />
                      <Route
                        path="/admin/updatedownloadmaster/:id"
                        component={UpdateDownloadMaster}
                      />
                      <Route path="/admin/addfaq" component={AddFAQ} />
                      <Route
                        path="/admin/updatefaq/:id"
                        component={UpdateFAQ}
                      />
                      <Route
                        path="/admin/addfranchiseecenter"
                        component={AddFranchiseeCenter}
                      />
                      <Route
                        path="/admin/updatefranchiseecenter/:id"
                        component={UpdateFranchiseeCenter}
                      />
                      <Route
                        path="/admin/addhomesliderimage"
                        component={AddHomeSliderImages}
                      />
                      <Route
                        path="/admin/addmailtemplate"
                        component={AddMailTemplate}
                      />
                      <Route
                        path="/admin/updatehomesliderimage/:id"
                        component={UpdateHomeSliderImage}
                      />
                      <Route
                        path="/admin/addmobilesliderimage"
                        component={AddMobileSliderImages}
                      />
                      <Route
                        path="/admin/updatemobilesliderimage/:id"
                        component={UpdateMobileSliderImages}
                      />
                      <Route
                        path="/admin/addtestimonialmaster"
                        component={AddTestimonialMaster}
                      />
                      <Route
                        path="/admin/updatetestimonialmaster/:id"
                        component={UpdateTestimonialMaster}
                      />
                      <Route
                        path="/admin/addincexpcat"
                        component={AddIncExpCat}
                      />
                      <Route
                        path="/admin/updateincexpcat/:id"
                        component={UpdateIncExpCat}
                      />
                      <Route
                        path="/admin/addexpensemode"
                        component={AddExpenseMode}
                      />
                      <Route
                        path="/admin/updateexpensemode/:id"
                        component={UpdateExpenseMode}
                      />
                      <Route
                        path="/admin/addCourseofflineorder"
                        component={AddOfflineOrder}
                      />
                      <Route
                        path="/admin/updateCourseofflineorder/:id"
                        component={UpdateOfflineOrder}
                      />
                      <Route
                        path="/admin/addexpenseentry"
                        component={AddExpenseEntry}
                      />
                      <Route
                        path="/admin/updateexpenseentry/:id"
                        component={UpdateExpenseEntry}
                      />
                      <Route
                        path="/admin/addincomeentry"
                        component={AddIncomeEntry}
                      />
                      <Route
                        path="/admin/updateincomeentry/:id"
                        component={UpdateIncomeEntry}
                      />
                      <Route path="/admin/addfaculty" component={AddFaculty} />
                      <Route
                        path="/admin/updatefaculty/:id"
                        component={UpdateFaculty}
                      />
                      <Route path="/admin/addvendor" component={AddVendor} />
                      <Route
                        path="/admin/updatevendor/:id"
                        component={UpdateVendor}
                      />
                      <Route
                        path="/admin/addlogistic"
                        component={AddLogistic}
                      />
                      <Route
                        path="/admin/updatelogistic/:id"
                        component={UpdateLogistic}
                      />
                      <Route
                        path="/admin/addtestseries"
                        component={AddTestSeries}
                      />
                      <Route
                        path="/admin/updatetestseries/:id"
                        component={UpdateTestSeries}
                      />
                      <Route
                        path="/admin/addquestion"
                        component={AddQuestion}
                      />
                      <Route
                        path="/admin/updatequestion/:id"
                        component={UpdateQuestion}
                      />
                      <Route path="/admin/addsubject" component={AddSubject} />
                      <Route
                        path="/admin/updatesubject/:id"
                        component={UpdateSubject}
                      />
                      <Route
                        path="/admin/addmediatype"
                        component={AddMediatype}
                      />
                      <Route
                        path="/admin/updatemediatype/:id"
                        component={UpdateMediatype}
                      />
                      <Route path="/admin/addostype" component={AddOStype} />
                      <Route
                        path="/admin/updateostype/:id"
                        component={UpdateOStype}
                      />
                      <Route
                        path="/admin/addviewsandvalidity"
                        component={AddViewsValidity}
                      />
                      <Route
                        path="/admin/updateviewsandvalidity/:id"
                        component={UpdateViewsValidity}
                      />
                      <Route
                        path="/admin/addbooklevel"
                        component={AddBookLevel}
                      />
                      <Route
                        path="/admin/updatebooklevel/:id"
                        component={UpdateBookLevel}
                      />
                      <Route
                        path="/admin/addbookstream"
                        component={AddBookStream}
                      />
                      <Route
                        path="/admin/updatebookstream/:id"
                        component={UpdateBookStream}
                      />
                      <Route
                        path="/admin/addbookmaster"
                        component={AddBookMaster}
                      />
                      <Route
                        path="/admin/updatebookmaster/:id"
                        component={UpdateBookMaster}
                      />
                      <Route
                        path="/admin/addbookcategory"
                        component={AddBookCategory}
                      />
                      <Route
                        path="/admin/updatebookcategory/:id"
                        component={UpdateBookCategory}
                      />
                      <Route
                        path="/admin/addextensionmaster"
                        component={AddExtensionMaster}
                      />
                      <Route
                        path="/admin/updateextensionmaster/:id"
                        component={UpdateExtensionMaster}
                      />
                      <Route
                        path="/admin/addtestcategory"
                        component={AddTestCategory}
                      />
                      <Route
                        path="/admin/addTest_Seriesofflineorder"
                        component={AddTestOrder}
                      />
                      <Route
                        path="/admin/updateTest_Seriesofflineorder/:id"
                        component={UpdateTestOrder}
                      />
                      <Route
                        path="/admin/addBooksofflineorder"
                        component={AddBookOrder}
                      />
                      <Route
                        path="/admin/updateBooksofflineorder/:id"
                        component={UpdateBookOrder}
                      />
                      <Route
                        path="/admin/addExtend_Validityofflineorder"
                        component={AddExtensionOrder}
                      />
                      <Route
                        path="/admin/updateExtend_Validityofflineorder/:id"
                        component={UpdateExtensionMaster}
                      />
                      <Route
                        path="/admin/updatetestcategory/:id"
                        component={UpdateTestCategory}
                      />
                      <Route
                        path="/admin/addfreeuser"
                        component={AddFreeUser}
                      />
                      <Route
                        path="/admin/addbookuser"
                        component={AddFreeUser}
                      />
                      <Route
                        path="/admin/addpremiumuser"
                        component={AddPremiunUser}
                      />
                      <Route
                        path="/admin/updatepremiumuser/:id"
                        component={UpdatePremiumUser}
                      />
                      <Route path="/admin/addad" component={AddAd} />
                      <Route path="/admin/updatead/:id" component={UpdateAd} />
                      <Route
                        path="/admin/adddownloadable"
                        component={AddDownloadable}
                      />
                      <Route
                        path="/admin/addappsubject"
                        component={AddAppSubject}
                      />
                      <Route
                        path="/admin/trackerAlottedUser"
                        component={Trackerdrops}
                      />
                      <Route
                        path="/admin/updateappsubject/:id"
                        component={UpdateAppSubject}
                      />
                      <Route
                        path="/admin/updatechapter/:id"
                        component={UpdateChapter}
                      />
                      <Route
                        path="/admin/updatequeries/:id"
                        component={UpdateQuery}
                      />
                      <Route
                        path="/admin/createQuestion"
                        component={CreateQuestion}
                      />
                      <Route
                        path="/admin/addincometype"
                        component={AddIncomeType}
                      />
                      <Route path="/admin/addlevel" component={AddLevel} />
                      <Route
                        path="/admin/updatelevel/:id"
                        component={UpdateLevel}
                      />
                      <Route
                        path="/admin/addappfaculty"
                        component={AddAppFaculty}
                      />
                      <Route
                        path="/admin/answeraquestion/:id"
                        component={AnsweraQuestion}
                      />
                      <Route
                        path="/admin/answerquery/:id"
                        component={AnswerQuery}
                      />
                      <Route
                        path="/admin/addnotification"
                        component={AddNotification}
                      />
                      <Route
                        path="/admin/expense_type_report/:type"
                        component={ExpenseTypeReport}
                      />
                      <Route
                        path="/admin/income_type_report/:type"
                        component={IncomeTypeReport}
                      />
                      <Route
                        path="/admin/updatenotification/:id"
                        component={UpdateNotification}
                      />
                      <Route
                        path="/admin/addannouncement"
                        component={AddAnnouncement}
                      />
                      <Route
                        path="/admin/updateannouncement/:id"
                        component={UpdateAnnouncement}
                      />
                      <Route
                        path="/admin/sendmessages"
                        component={SendMessages}
                      />
                      <Route
                        path="/admin/addWhatStudentsSay"
                        component={AddWhatStudentsSay}
                      />
                      <Route
                        path="/admin/updateWhatStudentsSay"
                        component={UpdateWhatStudentsSay}
                      />
                      <Route
                        path="/admin/addfacetofaceuser"
                        component={AddFaceToFaceUser}
                      />
                      <Route
                        path="/admin/updatefacetofaceuser"
                        component={UpdateFaceToFaceUser}
                      />
                      <Redirect from="/admin" to="/admin/dashboard" />
                    </Switch>
                  </div>
                </div>
              ) : (
                <div className={classes.map}>
                  <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/admin" to="/admin/dashboard" />
                  </Switch>
                </div>
              )}
              {getRoute() ? <Footer fluid /> : null}
              <FixedPlugin
                handleImageClick={handleImageClick}
                handleColorClick={handleColorClick}
                handleBgColorClick={handleBgColorClick}
                color={color}
                bgColor={bgColor}
                bgImage={image}
                handleFixedClick={handleFixedClick}
                fixedClasses={fixedClasses}
                sidebarMinimize={sidebarMinimize.bind(this)}
                miniActive={miniActive}
              />
            </div>
          </div>
        ) : (
          <div className={classes.content}>
            {/* <div className={classes.container}> */}
            {/* <AdminNavbar /> */}
            <Switch>
              {/* AK WEB ROUTE */}

              <Route
                path="/admin/akWebPortal/offlineorders"
                component={HomePage}
              />
              <Route
                path="/admin/akWebPortal/subjects/:id"
                component={Subjects}
              />
              <Route
                path="/admin/akWebPortal/combo_subjects/*"
                component={ComboSubjects}
              />
              <Route
                path="/admin/akWebPortal/course_landing/*"
                component={CourseLanding}
              />
              <Route
                path="/admin/akWebPortal/course_details/*"
                component={CourseDetails}
              />
              <Route
                path="/admin/akWebPortal/checkout/"
                component={FunctionalCheckout}
              />
            </Switch>
            {/* </div> */}
          </div>
        )}
      </div>
    );
  } else {
    window.location.href = "/signin";
  }
}
